






































































import { Component, Vue } from 'vue-property-decorator'
import { UserModel } from '@/core/models/UserModel'
import { AccountModel } from '../../models/AccountModel'
import AccountService from '../../AccountService'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import DepositInvoice from '@/modules/components/DepositInvoice.vue'
import { toLocalCurrency } from '@/utils/currencyFormatter'

@Component({
  name: 'AccountLog',
  components: {
    BaseIcon,
    BaseButton,
    DepositInvoice,
  },
})
export default class AccountLog extends Vue {
  toLocalCurrency = toLocalCurrency
  loading = false
  showDepositInvoice = false
  accountLog = [] as AccountModel[]

  @UserStore.Getter
  public currentUser!: UserModel

  @UserStore.Mutation('setUserAccount')
  setUserAccount!: (value: number) => void

  async getAccount(): Promise<AccountModel[]> {
    return await AccountService.getAccount()
  }

  async prepareAccount(): Promise<void> {
    this.loading = true
    const account = await this.getAccount()
    if (account instanceof Array) {
      if (account.length) {
        account.sort((a, b) => b.id - a.id)
        this.setUserAccount(account[0].value)
        this.accountLog = account
      } else {
        this.setUserAccount(0)
      }
    }
    this.loading = false
  }

  created(): void {
    this.prepareAccount()
  }
}
